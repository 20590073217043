import { analyticsHandler } from "core/events";
function initGA(gaDetails) {
    var _a;
    // Google code to init GA
    (function (i, s, o, g, r) {
        i["GoogleAnalyticsObject"] = r;
        (i[r] =
            i[r] ||
                function () {
                    (i[r].q = i[r].q || []).push(arguments);
                }),
            (i[r].l = new Date().getTime());
        let a = s.createElement(o), m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");
    ga("create", gaDetails.id, {
        name: (_a = gaDetails.trackerName) !== null && _a !== void 0 ? _a : "auto",
    });
}
function initGTM(gtmDetails) {
    // Google code to init GTM
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", gtmDetails.id);
}
function ga(...args) {
    window.ga(...args);
}
function gtm(event) {
    window.dataLayer.push(event);
}
var CONFIGURATION_ERROR;
(function (CONFIGURATION_ERROR) {
    CONFIGURATION_ERROR["GA_MISSING_IMPLEMENTATION"] = " GA implementation specified but no implementation has been provided";
    CONFIGURATION_ERROR["GTM_MISSING_IMPLEMENTATION"] = "GTM implementation specified but no implementation has been provided";
    CONFIGURATION_ERROR["UNKNOWN_IMPLEMENTATION"] = "implementation provided is not supported";
})(CONFIGURATION_ERROR || (CONFIGURATION_ERROR = {}));
class GAManager {
    constructor(appType, appSubtype, configuration) {
        this.appType = appType;
        this.appSubtype = appSubtype;
        this.configuration = configuration;
        this.implementation = null;
        this.error = null;
        switch (configuration.analytics.implementation) {
            case "ga": {
                if (!configuration.analytics.ga)
                    this.error = CONFIGURATION_ERROR.GA_MISSING_IMPLEMENTATION;
                else {
                    this.implementation = "ga";
                    initGA(configuration.analytics.ga);
                }
                break;
            }
            case "gtm": {
                if (!configuration.analytics.gtm)
                    this.error = CONFIGURATION_ERROR.GTM_MISSING_IMPLEMENTATION;
                else {
                    this.implementation = "gtm";
                    initGTM(configuration.analytics.gtm);
                }
                break;
            }
            default: {
                this.error = CONFIGURATION_ERROR.UNKNOWN_IMPLEMENTATION;
            }
        }
    }
    static getInstance(appType, appSubtype, configuration) {
        if (this.instance)
            return this.instance;
        this.instance = new GAManager(appType, appSubtype, configuration);
        return this.instance;
    }
    event(event) {
        if (!this.implementation)
            return;
        if (this.implementation === "ga") {
            ga(`${this.configuration.analytics.ga.trackerName}.send`, "event", {
                eventCategory: event.category,
                eventAction: event.action,
                eventLabel: event.label,
            });
        }
        if (this.implementation === "gtm") {
            this.resolveEventOptionals(event);
            gtm(event);
        }
    }
    pageView(application, page) {
        if (!this.implementation)
            return;
        if (this.implementation === "ga") {
            ga(`${this.configuration.analytics.ga.trackerName}.send`, {
                hitType: "pageview",
                page: `${application}|${page}`,
            });
        }
        if (this.implementation === "gtm") {
        }
    }
    resolveEventOptionals(event) {
        if (!this.configuration.analytics.events)
            return;
        if (this.configuration.analytics.events.ids) {
            const id = this.configuration.analytics.events.ids[event.action];
            if (id)
                event.id = id;
        }
        if (this.configuration.analytics.events.groups) {
            const group = this.configuration.analytics.events.groups[event.action];
            if (group)
                event.group = group;
        }
    }
}
export class StoreLocatorGAManager {
    constructor(configuration) {
        this.applicationType = "storelocator";
        this.applicationSubtype = "home";
        this.manager = GAManager.getInstance(this.applicationType, this.applicationSubtype, configuration);
        if (this.manager.error) {
            console.warn(`Analytics Configuration error: ${this.manager.error}`);
        }
        else {
            analyticsHandler.addListener((event) => {
                try {
                    const type = event.detail.type;
                    let analyticsEvent = {
                        category: `${this.applicationType}|${this.applicationSubtype}`,
                        action: "",
                    };
                    if (type === "pageview") {
                        return this.manager.pageView(this.applicationType, event.detail.subtype);
                    }
                    if (type === "geolocation_consent") {
                        analyticsEvent.action = `${event.detail.type}|${event.detail.subtype}`;
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "geolocation_html5") {
                        analyticsEvent.action = `${event.detail.type}|${event.detail.subtype}`;
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "geolocation_ip") {
                        analyticsEvent.action = `${event.detail.type}|${event.detail.subtype}`;
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "geolocation_default_position") {
                        analyticsEvent.action = `${event.detail.type}|${event.detail.subtype}`;
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "click_nearest_stores") {
                        analyticsEvent.action = event.detail.type;
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "free_search") {
                        analyticsEvent.action = `${event.detail.type}|${event.detail.subtype}`;
                        analyticsEvent.label = `${event.detail.payload.search}|${configuration.language}`;
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "filter") {
                        analyticsEvent.action = `${event.detail.type}|${event.detail.subtype}`;
                        analyticsEvent.label = `${event.detail.payload.name}|${configuration.language}`;
                        return this.manager.event(analyticsEvent);
                    }
                    // --- STORE RELATED EVENTS ---
                    analyticsEvent.action = `${event.detail.type}|${event.detail.subtype}`;
                    const { storeCode, city, address1 } = event.detail.payload.store;
                    analyticsEvent.label = `${storeCode}|${city}, ${address1}|${configuration.language}`;
                    if (type === "click_phone") {
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "click_whatsapp") {
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "click_mail") {
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "click_directions") {
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "click_store_details") {
                        return this.manager.event(analyticsEvent);
                    }
                    if (type === "click_store") {
                        return this.manager.event(analyticsEvent);
                    }
                }
                catch (e) {
                    console.warn(`Analytics error: ${e}`);
                }
            });
        }
    }
}
