import { useDirections } from "core/context/directions";
import { useStores } from "core/context/stores";
import { analyticsHandler } from "core/events";
import AccordionMenu from "main/components/accordion/Menu";
import React from "react";
function Component({ store, configuration, openingTimesCache }) {
    const { allServices } = useStores();
    const { setStore } = useDirections();
    function handlePhoneClick() {
        analyticsHandler.dispatch({
            type: "click_phone",
            subtype: "listing",
            payload: { store },
        });
    }
    function handleWebsiteClick() {
        analyticsHandler.dispatch({
            type: "click_website",
            subtype: "listing",
            payload: { store },
        });
    }
    function handleDirections() {
        analyticsHandler.dispatch({
            type: "click_directions",
            subtype: "listing",
            payload: { store },
        });
        setStore(store);
    }
    function handleStoreDetails() {
        analyticsHandler.dispatch({
            type: "click_store_details",
            subtype: "listing",
            payload: { store },
        });
        window.open(store.storeLink, "_blank");
    }
    const filteredServices = allServices.filter(s => s.in_homepage === 1 && store.services.includes(s.id));
    return (React.createElement("div", { className: "store" },
        React.createElement("p", { className: "store__name" }, `CRLAB ${store.city}`),
        store.country.tagISO31661Alpha2.toLowerCase() !== "it" && store.name !== "CRLAB" && (React.createElement("p", { className: "store__centername" }, store.name)),
        React.createElement("p", { className: "store__location" },
            store.address1,
            " ",
            store.postalCode,
            " ",
            store.city),
        React.createElement("p", { className: "store__phone" },
            React.createElement("a", { href: `tel:${store.phone}`, onClick: handlePhoneClick }, store.phone)),
        store.country.tagISO31661Alpha2.toLowerCase() !== "it" && (React.createElement("a", { className: "store__website", href: store.website, onClick: handleWebsiteClick }, store.website)),
        openingTimesCache.get(store.storeCode) && (React.createElement("p", { className: "store__opening" }, openingTimesCache.get(store.storeCode))),
        filteredServices.length > 0 && (React.createElement(AccordionMenu, { label: configuration.translations.list.storeCard.accordionText },
            React.createElement("ul", { className: "store__services" }, filteredServices.map(s => (React.createElement("li", { key: s.id, className: "service" },
                React.createElement("img", { className: "service__icon", src: s.icon }),
                React.createElement("p", { className: "service__name" }, s.name))))))),
        React.createElement("div", { className: "store__buttons-container" },
            React.createElement("button", { className: "store__button-directions", onClick: handleDirections }, configuration.translations.list.storeCard.directions),
            React.createElement("button", { className: "store__button-details", onClick: handleStoreDetails }, configuration.translations.list.storeCard.info))));
}
// const Component = memo(StoreCard);
export const dynamicComponent = {
    Component,
    checkExpectedProps: props => { },
};
