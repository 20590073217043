"use strict";
// import React, { FunctionComponentElement, ReactNode } from "react";
// import { DynamicComponent, PropsType } from "types/Representation";
// import { loadRepresentation } from "core/parser";
// interface MixinProps extends PropsType {
//     children: (props: PropsType) => ReactNode;
// }
// export function Component({ children, ...props }: MixinProps) {
//     function handleFiltersClick() {
//         console.log("filters click!");
//     }
//     return (
//         <>
//             <button className="rt-filters-button" onClick={handleFiltersClick}>
//                 Filters
//             </button>
//             {children(props)}
//         </>
//     );
// }
// export const dynamicComponent: DynamicComponent<MixinProps> = {
//     Component,
//     children: (repr, configuration) => (props: PropsType) => loadRepresentation(repr, configuration, props),
//     checkExpectedProps: (props) => {},
// };
